export default {
  blogerName: 'KINATWIN',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/kinatwin',
    },
    {
      name: 'tiktok',
      url: 'https://www.tiktok.com/@kinat_win',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@kinatwin',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs.com/c6926f8c4',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs.com/cb8f951d2',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c8b55c72e',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/c721da1a2',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir23.com/caa7dd782',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/ce8ae81d3',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-izpihgrzed.com/cdb389fe4',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-mutlukir.com/c2e2dddb1',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-irrs01.com/cda74cdca',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-nxoyfjmrn.com/cda4c73ec',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/cc9be57fb',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>KINATWIN</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'KINATWIN',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
